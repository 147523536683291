import React from "react";
import { Link } from "gatsby";

import christineLogo from "../../assets/image/png/christine-logo-2.png";
import christineLogoDark from "../../assets/image/png/christine-logo-2-dark.png";
import styled from "styled-components";
import { device } from "../../utils";

const StyledLink = styled(Link)`
  & > img {    
    width: 180px;
    margin: 12px 0;
    @media ${device.lg} {
      width: 300px;
      margin: 20px;
    }
  }
`;

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <StyledLink to="/" className={`${className}`} {...rest}>
      {white ? (
        <img src={christineLogo} alt="" />
      ) : (
        <img src={christineLogoDark} alt="" />
      )}
    </StyledLink>
  );
};

export default Logo;
