import React from "react";
import uniqueId from "lodash/uniqueId";
import styled, { css } from "styled-components";
import {
  space,
  border,
  typography,
  shadow,
  flexbox,
  layout,
} from "styled-system";

const CheckStyled = styled.label`
  display: inline-flex;
  cursor: pointer;

  input:checked ~ .check::before {
    content: "\f14a";
    font-family: "Font Awesome 5 free";
    font-weight: 900;
    color: ${({ theme }) => theme.colors.secondary};
  }

  ${border};
  ${space};
  ${typography};
  ${shadow};
  ${flexbox};
  ${layout};
`;

const StyledInput = styled.input`
  appearance: none;
  line-height: 1;
  top: -2px;
  margin-right: 8px;
  min-height: 20px;
  min-width: 20px;
  &::before {
    content: "\f0c8";
    font-family: "Font Awesome 5 free";
    font-size: 19px;
    color: #e2e4e8;
  }
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 0;

  ${(props) =>
    props.required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `};
`;

const Checkbox = ({
  children = "Keep me signed in",
  onClick = () => {},
  required = false,
  ...rest
}) => {
  const uID = uniqueId("check_");

  return (
    <CheckStyled {...rest} htmlFor={uID}>
      <StyledInput type="checkbox" id={uID} required={required} />
      <Text required={required}>{children}</Text>
    </CheckStyled>
  );
};

export default Checkbox;
