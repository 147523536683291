import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const result = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteTitle
          siteUrl
          siteCover
          siteDescription
          headerLinks {
            label
            url
            isButton
          }
          footerLinks {
            label
            url
          }
        }
      }
    }
  `);
  return result.site.siteMetadata;
};

export default useSiteMetadata;
