import React from "react";
import { Helmet } from "react-helmet";

export default React.memo(
  ({
    canonicalUrl,
    defaultTitle,
    description,
    image,
    organization,
    title,
    url,
  }) => {
    const schema = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url,
        name: title,
        alternateName: defaultTitle,
      },
    ];

    return (
      <Helmet>
        {/* Schema.org tags */}
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    );
  }
);
