import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import { StaticImage } from "gatsby-plugin-image";
import Logo from "../Logo";
import { Link } from "gatsby";
import useSiteMetadata from "../../hooks/use-site-config";

const StyledLink = styled(Link)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
`;

const StyledContainer = styled(Container)`
  a {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade} !important;
    font-size: 16px;
    font-weight: 400;
    transition: 0.4s;
    padding: 0 3px;
    margin: 0 2.5px;
    &:visited {
      text-decoration: none;
    }
    &:hover {
      text-decoration: none;
      color: ${({ theme, color }) => theme.colors.secondary} !important;
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  const { footerLinks } = useSiteMetadata();
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <StyledContainer dark={isDark ? 1 : 0}>
          <Box
            css={`
              padding: 50px 0 50px;
            `}
          >
            <Row className="justify-content-center">
              <Logo white={isDark} />
            </Row>
            <Row className="justify-content-center">
              {footerLinks.map(({ url, label }) => (
                <div className="mt-3 px-5">
                  <StyledLink
                    to={url}
                    variant="card"
                    color={isDark ? "light" : "dark"}
                  >
                    {label}
                  </StyledLink>
                </div>
              ))}
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; 2021 Christine Colman, All Rights Reserved</p>
                <p>ABN: 53 463 175 953</p>
              </Col>
              <Col sm="6" className="d-flex justify-content-end">
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://www.linkedin.com/in/christine-colman-77497754"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="icon icon-logo-linkedin"></i>
                    </a>
                  </li>
                </ul>
                <a href="https://www.vainstitute.com.au/">
                  <StaticImage
                    src="../../assets/image/png/va-certified.png"
                    alt="virtual assistant certification"
                    width={200}
                  />
                </a>
              </Col>
            </Row>
          </CopyRightArea>
        </StyledContainer>
      </Box>
    </>
  );
};

export default Footer;
