import React from "react";
import styled, { css } from "styled-components";
import {
  color,
  space,
  typography,
  shadow,
  layout,
  border,
} from "styled-system";

const InputSolid = styled.input`
  font-size: 18px;
  font-weight: 300;
  letter-spacing: -0.56px;
  display: block;
  padding: 1.275rem 1.75rem;
  background-clip: padding-box;
  border: ${({ theme }) => `1px solid ${theme.colors.border}`};
  border-radius: 10px;
  ${color};
  ${space};
  ${typography};
  ${shadow};
  ${layout};
  ${border};
  &:focus,
  &:active,
  &.active {
    border-color: ${({ theme, focusColor }) => theme.colors[focusColor]};
    outline: 0;
    box-shadow: none;
  }
`;

const StyledLabel = styled.label`
  ${(props) =>
    props.required &&
    css`
      &:after {
        content: " *";
        color: red;
      }
    `}
`;

const Input = ({
  variant = "solid",
  type = "text",
  focusColor = "secondary",
  placeholder,
  label,
  css,
  required,
  ...rest
}) => {
  return (
    <>
      <StyledLabel required={required}>{label}</StyledLabel>
      <InputSolid
        width="100%"
        type={type}
        color="text"
        bg="light"
        placeholder={placeholder}
        focusColor={focusColor}
        css={css}
        required={required}
        {...rest}
      />
    </>
  );
};

export default Input;
