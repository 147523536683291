import React from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import SchemaOrg from "./schema-org";
import useSiteMetadata from "../../hooks/use-site-config";
import useSiteImages from "../../hooks/use-site-images";
import imgFavicon from "../../assets/favicon.png";

const SEO = (props) => {
  const { path = "" } = props;
  const { siteTitle, siteUrl, siteCover, siteDescription } = useSiteMetadata();
  const { fluid } = useSiteImages(siteCover);
  const image = fluid.src;
  const title = props.title ? `${props.title} - ${siteTitle}` : `${siteTitle}`;
  const formatedSiteUrl = siteUrl.endsWith("/")
    ? siteUrl.substring(0, siteUrl.length - 1)
    : siteUrl;

  const description = props.description || siteDescription;
  const url = formatedSiteUrl + withPrefix(path);

  return (
    <>
      <Helmet title={title}>
        {/* General tags */}
        <html />
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />
        <link rel="icon" type="image/png" href={imgFavicon} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />

        {/* Twitter Card tags */}
        {/* <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} /> */}
      </Helmet>
      <SchemaOrg
        url={url}
        title={title}
        image={image}
        description={description}
        canonicalUrl={url}
        organization={siteTitle}
        defaultTitle={title}
      />
    </>
  );
};

export default SEO;
